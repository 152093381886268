/**
 * Zustand store
 */

/* global window */
import create from 'zustand';
import humps from 'lodash-humps';
import isArray from 'lodash/isArray';
import omit from 'lodash/fp/omit';
import queryString from 'query-string';
import toNumber from 'lodash/toNumber';

import languageOptions from './languageOptions';
import programOptions from './academicProgramsOfferedOptions';
import extracurricularOptions from './extracurricularOptions';
import setUrlParams from './setUrlParams';
import toggleOverlay from './toggleOverlay';

// Convert url params sent from the homepage and add default values to the store
const parsed = queryString.parse(window.location.search);

const useStore = create((set, get) => ({
	activeMarker: null,
	activeMobileTab: parsed.activeMobileTab || 'list',
	address: parsed.address,
	addressCoordinates: parsed.lat && parsed.lng && {
		lat: toNumber(parsed.lat),
		lng: toNumber(parsed.lng),
	},
	defaultAddress: parsed.address,
	defaultCoordinates: { lat: 44.9778, lng: -93.2650 },
	defaultGradeLevel: parsed.gradeLevel,
	defaultTransportation: parsed.transportation,
	freeOnly: parsed.freeOnly && parsed.freeOnly === 'true',
	gradeLevel: parsed.gradeLevel,
	isLoading: true,
	isValidAddress: !!(parsed.lat && parsed.lng),
	itemsPerPage: 10,
	map: null,
	page: parsed.currentPage ? parseInt(parsed.currentPage, 10) : 1,
	schools: [],
	showMobileFilters: false,
	sort: parsed.sort || 'score',
	transportation: parsed.transportation,
	languages: parsed.languages ? parsed.languages.split('|').map((item) => decodeURIComponent(item)).filter((item) => languageOptions.includes(item)) : [],
	academicProgramsOffered: parsed.academicProgramsOffered ? parsed.academicProgramsOffered.split('|').map((item) => decodeURIComponent(item)).filter((item) => programOptions.includes(item)) : [],
	extracurriculars: parsed.extracurriculars ? parsed.extracurriculars.split('|').map((item) => decodeURIComponent(item)).filter((item) => extracurricularOptions.includes(item)) : [],
	showMoreExtracurriculars: false,
	toggleShowMoreExtracurriculars: () => {
		set(() => ({ showMoreExtracurriculars: !get().showMoreExtracurriculars }));
	},
	showMoreLanguages: false,
	toggleShowMoreLanguages: () => {
		set(() => ({ showMoreLanguages: !get().showMoreLanguages }));
	},
	showMorePrograms: false,
	toggleShowMorePrograms: () => {
		set(() => ({ showMorePrograms: !get().showMorePrograms }));
	},
	showMoreFilters: false,
	hideMoreFilters: () => {
		set(() => ({ showMoreFilters: false }));
	},
	toggleMoreFilters: () => {
		set(() => ({ showMoreFilters: !get().showMoreFilters }));
	},
	toggleMobileFilters: () => {
		set(() => ({ showMobileFilters: !get().showMobileFilters }));
		toggleOverlay(get().showMobileFilters);
	},
	setActiveMarker: (activeMarker) => {
		set(() => ({ activeMarker }));
		get().setUrlParams();
	},
	setActiveMobileTab: (activeMobileTab) => {
		set(() => ({ activeMobileTab }));
		get().setUrlParams();
	},
	setAddressCoordinates: (lat, lng) => set(() => ({ addressCoordinates: { lat, lng } })),
	setFormVals: (formVals) => {
		set(() => ({
			...omit(['address'], formVals),
			activeMarker: false,
			address: isArray(formVals.address) ? formVals.address[0] : formVals.address,
			page: 1,
		}));
		get().setUrlParams();
	},
	setIsLoading: (isLoading) => {
		set(() => ({
			isLoading,
			showMobileFilters: false,
		}));
		toggleOverlay(false);
	},
	setMap: (map) => set(() => ({ map })),
	setPage: (page) => {
		set(() => ({ isLoading: true }));
		setTimeout(() => {
			set(() => ({ page, isLoading: false }));
			get().setUrlParams();
		}, 500);
	},
	setSchools: (schools) => set(() => ({
		schools: humps(schools),
		isLoading: false,
	})),
	setSort: (sort) => {
		set(() => ({ isLoading: true }));
		setTimeout(() => {
			set(() => ({ sort, page: 1, isLoading: false }));
			get().setUrlParams();
		}, 200);
	},
	setUrlParams: () => setUrlParams(get),
	setValidAddress: (isValidAddress) => set(() => ({ isValidAddress })),
	maxTuition: parsed.maxTuition,
	setMaxTuition: (maxTuition) => set(() => ({ maxTuition })),
	minTuition: parsed.minTuition,
	setMinTuition: (minTuition) => set(() => ({ minTuition })),
	beforeSchoolCare: parsed.beforeSchoolCare && parsed.beforeSchoolCare === 'true',
	afterSchoolCare: parsed.afterSchoolCare && parsed.afterSchoolCare === 'true',
	uniforms: parsed.uniforms && parsed.uniforms === 'true',
	setBeforeSchoolCare: (beforeSchoolCare) => set(() => ({ beforeSchoolCare })),
	setAfterSchoolCare: (afterSchoolCare) => set(() => ({ afterSchoolCare })),
	setUniforms: (uniforms) => set(() => ({ uniforms })),
}));

export default useStore;
