/**
 * Track relevant state values in the url. @TODO: probably just use a router.
 */
import isArray from 'lodash/isArray';

/* global window */
const updateUrl = (state) => {
	/* eslint-disable no-param-reassign */
	if (isArray(state.academicProgramsOffered)) {
		state.academicProgramsOffered = state.academicProgramsOffered.map((item) => encodeURIComponent(item)).join('|');
	}
	if (isArray(state.extracurriculars)) {
		state.extracurriculars = state.extracurriculars.map((item) => encodeURIComponent(item)).join('|');
	}
	if (isArray(state.languages)) {
		state.languages = state.languages.map((item) => encodeURIComponent(item)).join('|');
	}
	const params = new URLSearchParams(state);
	window.history.replaceState({}, '', decodeURIComponent(`${window.location.pathname}?${params}`));
};

export default updateUrl;
