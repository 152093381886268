/* eslint-disable no-tabs, indent, react/jsx-filename-extension */
/* globals document, wp */
import React from 'react';
import { createRoot } from 'react-dom/client';

import BusMap from './BusMap';
import HomepageForm from './HomepageForm';
import Search from './Search';
import RatingsVideo from './RatingsVideo';
import ProfileVideo from './ProfileVideo';

(() => {
	wp.domReady(() => {
		/* Homepage search form */
		const hpf = document.getElementById('msf-homepage-form-wrapper');
		if (hpf) {
			const root = createRoot(hpf);
			root.render(<HomepageForm />);
		}

		/* Search results page */
		const search = document.getElementById('msf-search-results-wrapper');
		if (search) {
			const root = createRoot(search);
			root.render(<Search />);
		}

		/* Where the bus goes */
		const areaMap = document.getElementById('msf-bus-map');
		if (areaMap) {
			const root = createRoot(areaMap);
			root.render(<BusMap />);
		}

		/* Ratings video */
		const ratingsVideo = document.getElementById('ratings-video');
		if (ratingsVideo) {
			const root = createRoot(ratingsVideo);
			const videoUrl = ratingsVideo.getAttribute('data-video');
			const linkUrl = ratingsVideo.getAttribute('data-link') || '';
			root.render(<RatingsVideo videoUrl={videoUrl} linkUrl={linkUrl} />);
		}

		/* Profile videos */
		const profileVideos = document.getElementsByClassName('profile-video-modal');
		if (profileVideos) {
			Array.from(profileVideos).forEach((video) => {
				const root = createRoot(video);
				const profileVideoUrl = video.getAttribute('data-video');
				const label = video.getAttribute('data-label');
				const hidePrefix = video.getAttribute('data-hide-prefix');
				root.render(
					<ProfileVideo
						hidePrefix={hidePrefix !== null}
						videoUrl={profileVideoUrl}
						label={label}
					/>,
				);
			});
		}
	});
})();
