/**
 * Component for search results in list form.
 */

/* eslint-disable no-tabs, indent, react/jsx-filename-extension, react/jsx-indent,
	react/jsx-indent-props */
import find from 'lodash/fp/find';
import map from 'lodash/fp/map';
import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';

import getSchools from '../lib/getSchools';
import SearchPagination from './SearchPagination';
import SearchResultsTeaser from './SearchResultsTeaser';
import sortStyles from '../lib/sortStyles';
import useStore from '../lib/useStore';
import translate from '../lib/translate';

const SearchResults = ({ containerRef }) => {
	let schools = useStore((state) => getSchools(state));
	const resultCount = schools.length;
	const {
		itemsPerPage,
		page,
		schools: unfiltered,
		setSort,
		sort,
	} = useStore();
	if (resultCount > itemsPerPage) {
		const start = (page - 1) * itemsPerPage;
		const end = start + itemsPerPage;
		schools = schools.slice(start, end);
	}
	const sortOptions = [
		{ value: 'score', label: translate('Performance', 'Rendimiento', 'Heerkooda shaqada') },
		{ value: 'proximity', label: translate('Proximity', 'Proximidad', 'Masaafo ahaan') },
		{ value: 'alpha', label: translate('Alphabetical', 'Orden alfabético', 'Alifbeeto ahaan') },
	];
	return (
		<div className="results-list-wrapper">
			<div className="results-summary">
				{(resultCount > 0) ? (
					<span className="number-results">
						{translate(
							`${resultCount} result${resultCount > 1 ? 's' : ''}`,
							`${resultCount} resultados`,
							`Natiijooyinka ${resultCount}`,
						)}
					</span>
				) : null}
				<label htmlFor="results-sort-order">
					<span>
						{translate('Sort by:', 'Ordenar por:', 'Usoo kala hormari:')}
					</span>
					<Select
						className="results-sort-order"
						id="results-sort-order"
						isSearchable={false}
						onChange={(val) => setSort(val.value)}
						options={sortOptions}
						value={find(['value', sort], sortOptions)}
						styles={sortStyles}
					/>
				</label>
			</div>
			{(schools.length || !unfiltered.length) ? (
				<ul className="results-list">
					{map((school) => (
						<SearchResultsTeaser key={`list-teaser-${school.id}`} school={school} />
					), schools)}
				</ul>
			) : (
				<div className="results-list-no-results">
					{translate('No results found', 'No hay resultados.', 'Wax natiijooyin ah maleh.')}
				</div>
			)}
			{(resultCount > itemsPerPage) ? (
				<SearchPagination
					containerRef={containerRef}
					resultCount={resultCount}
				/>
			) : null}
		</div>
	);
};

SearchResults.propTypes = {
	containerRef: PropTypes.shape({
		current: PropTypes.shape({}),
	}),
};

SearchResults.defaultProps = {
	containerRef: null,
};

export default SearchResults;
