/* eslint-disable no-tabs, indent, react/jsx-filename-extension, react/jsx-indent,
	react/jsx-indent-props */
import PropTypes from 'prop-types';
import React from 'react';
import SmoothScroll from 'smooth-scroll';

import useStore from '../lib/useStore';

const SearchToggle = ({ resultsMapRef }) => {
	const { activeMobileTab, setActiveMobileTab } = useStore();
	return (
		<div className="results-toggle">
			<button
				className={`toggle-list-results${activeMobileTab === 'list' && ' active'}`}
				onClick={() => setActiveMobileTab('list')}
			>
				<i className="fa fa-list" />List
			</button>
			<button
				className={`toggle-map-results${activeMobileTab === 'map' && ' active'}`}
				onClick={() => {
					setActiveMobileTab('map');
					setTimeout(() => {
						const scroll = new SmoothScroll();
						if (resultsMapRef && resultsMapRef.current) {
							scroll.animateScroll(resultsMapRef.current, null, {
								durationMax: 100,
								speed: 100,
								speedAsDuration: true,
							});
						}
					}, 50);
				}}
			>
				<i className="fa fa-map-marker" />Map
			</button>
		</div>
	);
};

SearchToggle.propTypes = {
	resultsMapRef: PropTypes.shape({
		current: PropTypes.shape({}),
	}),
};

SearchToggle.defaultProps = {
	resultsMapRef: null,
};

export default SearchToggle;
