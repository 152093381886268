/* global window, document */
import axios from 'axios';
import classnames from 'classnames';
import humps from 'lodash-humps';
import map from 'lodash/fp/map';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useWindowWidth } from '@react-hook/window-size';

import dropdownStyles from '../lib/dropdownModalStyles';
import isMobile from '../lib/isMobile';
import Modal from './Modal';
import translate from '../lib/translate';

const SchoolSelector = () => {
	const [showModal, setModal] = useState(false);
	const [schools, setSchools] = useState([]);
	const [selected, setSelected] = useState();
	const windowWidth = useWindowWidth();
	const ref = useOnclickOutside(() => setModal(false));
	useEffect(() => {
		axios.get(`${translate('/', '/es/', '/so/')}wp-json/schools/v1/all`)
			.then((response) => {
				setSchools(
					map((school) => ({
						value: school.postName,
						label: school.postTitle,
					}), humps(response.data)),
				);
			});
	}, []);
	return (
		<div className="dss-container">
			{showModal ? (
				<Modal>
					<div className="dss-modal">
						<div className="dss-wrapper" ref={ref}>
							<button type="button" className="close" onClick={() => setModal(false)}>
								{translate('Close', 'Cerrar', 'Xir')}
							</button>
							<h2>
								{translate(
									'Find by School Name',
									'Buscar una escuela por el nombre',
									'Ku raadi dugsiga magac ahaan',
								)}
							</h2>
							<form
								onSubmit={(evt) => {
									evt.preventDefault();
									if (selected.value) {
										window.location.href = `${translate('/', '/es/', '/so/')}school-profile/${selected.value}`;
									}
								}}
							>
								<div className="form-group">
									<label htmlFor="dss-select">
										<span className="sr-only">
											{translate(
												'Find by School Name',
												'Buscar una escuela por el nombre',
												'Ku raadi dugsiga magac ahaan',
											)}
										</span>
										<Select
											className={classnames({
												'has-selection': selected && selected.value,
											})}
											isSearchable
											onChange={(school) => setSelected(school)}
											openMenuOnFocus
											options={schools}
											placeholder={translate(
												'Type or choose a school',
												'Escriba o escoja una escuela',
												'Qor ama dooro dugsi',
											)}
											styles={dropdownStyles(windowWidth)}
											value={selected}
										/>
									</label>
								</div>
								<button disabled={!selected} type="submit">
									{translate(
										'View profile',
										'Ver el perfil',
										'Eeg Borofaylka',
									)}
								</button>
							</form>
						</div>
					</div>
				</Modal>
			) : null}
			<button className="dss-trigger" type="button" onClick={() => setModal(true)}>
				<span
					className={classnames({
						'sr-only': windowWidth < 1050 && !document.body.classList.contains('home')
							&& !isMobile(windowWidth),
					})}
				>
					{translate(
						'Find by School Name',
						'Buscar una escuela por el nombre',
						'Ku raadi dugsiga magac ahaan',
					)}
				</span>
			</button>
		</div>
	);
};

export default SchoolSelector;
