import getSite from './getSite';

const translate = (english, spanish, somali) => {
	/* eslint-disable indent */
	switch (getSite()) {
		case 1:
			return english;
		case 2:
			return spanish;
		case 3:
			return somali;
		default:
			break;
	}
	return english;
};

export default translate;
