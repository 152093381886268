/* eslint-disable no-tabs, indent, react/jsx-filename-extension, react/jsx-indent,
	react/jsx-indent-props, react/prop-types */
import classnames from 'classnames';
import map from 'lodash/fp/map';
import React from 'react';
import omit from 'lodash/fp/omit';
import usePlacesAutocomplete, {
	getGeocode,
	getLatLng,
} from 'use-places-autocomplete';
import { Typeahead } from 'react-bootstrap-typeahead';

import translate from '../lib/translate';

const Address = ({
	field,
	fieldState,
	isHovering,
	setCoordinates,
}) => {
	const {
		ready,
		setValue,
		suggestions: { status, data },
	} = usePlacesAutocomplete({
		requestOptions: {
			locationbias: 'circle:16000@44.9778,-93.2650',
		},
		debounce: 250,
	});

	const onInputChange = (value) => {
		field.onChange(value);
		setCoordinates(null);
		setValue(value);
	};

	const onChange = (value) => {
		field.onChange(value);
		if (value && value[0]) {
			getGeocode({ address: value[0] }).then((results) => {
				const { lat, lng } = getLatLng(results[0]);
				setCoordinates({ lat, lng });
			});
		}
	};

	// Don't display the autocomplete until user has interacted with the input.
	// Since we're getting address autocompletes on the fly it would alway say
	// "No matches found." on initial load otherwise.
	const open = fieldState.isDirty ? { } : { open: false };

	return (
		<Typeahead
			{...omit(['onChange'], field)}
			{...open}
			aria-describedby="typeaheadError"
			className={classnames({
				'is-invalid': fieldState.invalid,
				'is-hovered': isHovering,
			})}
			clearButton
			disabled={!ready}
			id="msf-hpf-address"
			name="address"
			onChange={onChange}
			onInputChange={onInputChange}
			options={status === 'OK' ? map((item) => item.description, data) : []}
			placeholder={translate('Your address', 'Su dirección', 'Ciwaankaaga')}
		/>
	);
};

export default Address;
