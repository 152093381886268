/**
 * Helper function for filtering by the selected grade level.
 */
import filter from 'lodash/fp/filter';
import get from 'lodash/fp/get';
import { createSelector } from 'reselect';

const filterByGrade = createSelector(
	get('schools'),
	get('gradeLevel'),
	(schools, gradeLevel) => {
		if (gradeLevel && gradeLevel !== 'all') {
			return filter((school) => (
				school.gradeFilter && school.gradeFilter.includes(gradeLevel)
			), schools);
		}
		return schools;
	},
);

export default filterByGrade;
