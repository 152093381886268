import PropTypes from 'prop-types';
import React from 'react';
import ShortUniqueId from 'short-unique-id';
import Toggle from 'react-toggle';
import { Controller } from 'react-hook-form';

import useStore from '../lib/useStore';

const CostFilter = ({ register, control, watch }) => {
	const uuid = new ShortUniqueId({ length: 28, dictionary: 'alpha' });
	const {
		maxTuition,
		minTuition,
		freeOnly,
	} = useStore();
	const freeOnlyFormVal = watch('freeOnly');
	const minId = `min-${uuid.rnd()}`;
	const maxId = `max-${uuid.rnd()}`;
	return (
		<fieldset className="more-filters-cost">
			<legend>Cost of Tuition</legend>
			<div>
				<label className="min-cost-filter" htmlFor={minId}>
					<span className="sr-only">Minimum</span>
					<select
						defaultValue={minTuition}
						disabled={freeOnlyFormVal}
						id={minId}
						{...register('minTuition')}
					>
						<option value="">No minimum</option>
						<option value="1000">$1,000</option>
						<option value="5000">$5,000</option>
						<option value="10000">$10,000</option>
					</select>
				</label>
				<label className="max-cost-filter" htmlFor={minId}>
					<span className="sr-only">Maximum</span>
					<select
						defaultValue={maxTuition}
						disabled={freeOnlyFormVal}
						id={maxId}
						{...register('maxTuition')}
					>
						<option value="">No maximum</option>
						<option value="1000">$1,000</option>
						<option value="5000">$5,000</option>
						<option value="10000">$10,000</option>
						<option value="20000">$20,000</option>
						<option value="30000">$30,000</option>
					</select>
				</label>
			</div>
			<Controller
				name="freeOnly"
				control={control}
				defaultValue={freeOnly}
				render={({ field: { onChange } }) => (
					<label htmlFor="more-filters-free-only">
						<Toggle
							checked={freeOnlyFormVal}
							icons={false}
							id="more-filters-free-only"
							onChange={(e) => onChange(e.target.checked)}
						/>
						Show Free Schools Only
					</label>
				)}
			/>
		</fieldset>
	);
};

CostFilter.propTypes = {
	register: PropTypes.func.isRequired,
	control: PropTypes.shape({}).isRequired,
	watch: PropTypes.func.isRequired,
};

export default CostFilter;
