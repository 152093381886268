import React, { useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import YouTube from 'react-youtube';
import { PropTypes } from 'prop-types';
import { useWindowSize } from '@react-hook/window-size';

import Modal from './components/Modal';
import toggleOverlay from './lib/toggleOverlay';
import translate from './lib/translate';

const RatingsVideo = ({ videoUrl, linkUrl }) => {
	const [showModal, setShowModal] = useState(false);
	const [windowWidth, windowHeight] = useWindowSize({ leading: true });
	const videoId = videoUrl.split('v=')[1];
	const ref = useOnclickOutside(() => {
		setShowModal(false);
		toggleOverlay(false);
	}, { detectIFrame: false });
	const width = windowWidth > 1000 ? 900 : windowWidth - 100;
	let height = windowWidth > 1000 ? 506 : 506 * (windowWidth / 900);
	if (height > windowHeight - 200) height = windowHeight - 200;
	const opts = {
		height,
		playerVars: {
			// https://developers.google.com/youtube/player_parameters
			autoplay: 1,
		},
		rel: 0,
		width,
	};
	return (
		<div>
			{showModal ? (
				<Modal>
					<div className="ratings-video-modal">
						<div className="ratings-video-wrapper" ref={ref}>
							<button
								type="button"
								className="close"
								onClick={() => {
									setShowModal(false);
									toggleOverlay(false);
								}}
							>
								{translate('Close', 'Cerrar', 'Xir')}
							</button>
							<YouTube videoId={videoId} opts={opts} />
							{linkUrl && (
								<a className="ratings-video-link" href={linkUrl}>
									Read more about our methodology
								</a>
							)}
						</div>
					</div>
				</Modal>
			) : (
				<button
					type="button"
					className="how-we-calculate"
					onClick={() => {
						setShowModal(true);
						toggleOverlay(true);
					}}
				>
					<span>{translate('How we calculate ratings', 'Cómo calculamos las calificaciones', 'Sida aan u xisaabino qiimeynta')}</span>
				</button>
			)}
		</div>
	);
};

RatingsVideo.propTypes = {
	linkUrl: PropTypes.string.isRequired,
	videoUrl: PropTypes.string.isRequired,
};

export default RatingsVideo;
