/**
 * Popup component for the mobile version of the infowindow. This one just sits
 * at the bottom of the map and is not tied to coordinates.
 */

/* eslint-disable no-tabs, indent, react/jsx-filename-extension, react/jsx-indent,
	react/jsx-indent-props, react/no-danger */
import PropTypes from 'prop-types';
import React from 'react';

import useStore from '../lib/useStore';
import translate from '../lib/translate';

const SearchMobilePopup = ({ activeSchool }) => {
	const { setActiveMarker } = useStore();
	return (
		<div className="result-info-window-mobile">
			<button onClick={() => setActiveMarker(null)}>&times;</button>
			<a href={`${translate('/', '/es/', '/so/')}school-profile/${activeSchool.postName}`}>
				<div className="group-left">
					<h2 className="school-title">{activeSchool.postTitle}</h2>
					{activeSchool.subtitle && <div className="school-subtitle">{activeSchool.subtitle}</div>}
					{activeSchool.grades && (
						<div className="school-grades">
							{`${translate('Grades', 'Grados', 'Darajooyin')} ${activeSchool.grades}`}
						</div>
					)}
					{activeSchool.transportationAvailable && (
						<div className="transportation-available">
							<div>
								{translate('Transportation available', 'Transporte disponible', 'Gaadiidka diyaara ah')}
							</div>
						</div>
					)}
					{activeSchool.performanceRating ? (
						<div dangerouslySetInnerHTML={{ __html: activeSchool.performanceRating }} />
					) : null}
				</div>
				<div className="group-right">
					{activeSchool.transportationAvailable && (
						<div className="transportation-available-icons">
							<div>
								<span className="walk-icon" />
								<span className="bus-icon" />
							</div>
						</div>
					)}
				</div>
			</a>
		</div>
	);
};

SearchMobilePopup.propTypes = {
	activeSchool: PropTypes.shape({
		id: PropTypes.number.isRequired,
		coordinates: PropTypes.shape({
			lat: PropTypes.number,
			lng: PropTypes.number,
		}).isRequired,
		grades: PropTypes.string,
		performanceRating: PropTypes.string,
		postName: PropTypes.string.isRequired,
		postTitle: PropTypes.string.isRequired,
		subtitle: PropTypes.string,
		transportationAvailable: PropTypes.bool,
	}).isRequired,
};

export default SearchMobilePopup;
