/**
 * Helper function processing the state for rendering. Basically mapStateToProps
 */
import appendGeo from './appendGeo';
import filterByCare from './filterByCare';
import filterByCost from './filterByCost';
import filterByExtracurriculars from './filterByExtracurriculars';
import filterByGrade from './filterByGrade';
import filterByLanguages from './filterByLanguages';
import filterByPrograms from './filterByPrograms';
import filterByTransportation from './filterByTransportation';
import filterByUniforms from './filterByUniforms';
import sortSchools from './sortSchools';

const getSchools = (state) => {
	let schools = appendGeo(state);
	schools = filterByTransportation({
		...state,
		schools,
	});
	schools = filterByGrade({
		...state,
		schools,
	});
	schools = filterByLanguages({
		...state,
		schools,
	});
	schools = filterByPrograms({
		...state,
		schools,
	});
	schools = filterByExtracurriculars({
		...state,
		schools,
	});
	schools = filterByCost({
		...state,
		schools,
	});
	schools = filterByCare({
		...state,
		schools,
	});
	schools = filterByUniforms({
		...state,
		schools,
	});
	schools = sortSchools({
		...state,
		schools,
	});
	return schools;
};

export default getSchools;
