/**
 * Search filters component for main search page.
 */
import classnames from 'classnames';
import find from 'lodash/fp/find';
import isArray from 'lodash/isArray';
import PropTypes from 'prop-types';
import React, { createRef } from 'react';
import Select from 'react-select';
import useHover from '@react-hook/hover';
import { getGeocode, getLatLng } from 'use-places-autocomplete';
import { useForm, Controller } from 'react-hook-form';
import { useInViewRef } from 'rooks';
import { useWindowSize } from '@react-hook/window-size';

import dropdownStyles from '../lib/dropdownStyles';
import gradeOptions from '../lib/gradeOptions';
import isMobile from '../lib/isMobile';
import MoreFilters from './MoreFilters';
import SchoolSelector from './SchoolSelector';
import SearchAddress from './SearchAddress';
import translate from '../lib/translate';
import transportationOptions from '../lib/transportationOptions';
import useStore from '../lib/useStore';

const SearchFilters = ({ containerRef }) => {
	const {
		academicProgramsOffered,
		defaultAddress,
		defaultGradeLevel,
		defaultTransportation,
		extracurriculars,
		hideMoreFilters,
		languages,
		setAddressCoordinates,
		setFormVals,
		setIsLoading,
		setValidAddress,
		showMobileFilters,
	} = useStore();
	// React hook form
	const {
		control,
		formState: { errors },
		handleSubmit,
		register,
		resetField,
		watch,
	} = useForm({
		defaultValues: {
			academicProgramsOffered,
			extracurriculars,
			languages,
		},
	});
	const [resetRef, resetInView] = useInViewRef();
	const [windowWidth, windowHeight] = useWindowSize({ leading: true });
	// Form submit handler
	const onSubmit = (res) => {
		setIsLoading(true);
		hideMoreFilters();
		/* global document */
		const container = document.getElementById('msf-search-results-wrapper');
		if (container && typeof container.scrollIntoView === 'function') container.scrollIntoView();
		// Synthetic load to give the experience of a load. Drop the 300ms
		// timeout for a more performant search! It was a little weird to have
		// instant results with a submit button.
		setTimeout(() => {
			const filteredVals = res;
			const { address: submitted } = filteredVals;
			const address = isArray(submitted) ? submitted[0] : submitted;
			getGeocode({ address }).then((results) => {
				const { lat, lng } = getLatLng(results[0]);
				setAddressCoordinates(lat, lng);
			}).catch((err) => {
				/* eslint-disable indent */
				switch (err) {
					case 'ZERO_RESULTS': {
						setValidAddress(false);
						break;
					}
					default:
						break;
				}
				/* eslint-enable indent */
			}).finally(() => {
				setFormVals(filteredVals);
				setIsLoading(false);
			});
		}, 300);
	};
	const defaultAddressValue = defaultAddress ? { defaultValue: defaultAddress } : {};
	const isValidAddress = useStore((state) => state.isValidAddress);
	let styles = {};
	// For mobile, add some inline styles to make the filters the right size
	// for a partial overlay.
	if (isMobile(windowWidth)) {
		let height = windowHeight;
		const { current } = containerRef;
		// Height should be the window height minus anything visible above the
		// container
		if (current) height -= current.getBoundingClientRect().top;
		styles = {
			height,
			left: '50%',
			marginLeft: `-${windowWidth / 2}px`,
			position: 'absolute',
			top: 0,
			width: windowWidth,
		};
	}

	const addressRef = createRef(null);
	const isHovering = useHover(addressRef);

	const resetFilters = () => {
		resetField('academicProgramsOffered', { defaultValue: [] });
		resetField('extracurriculars', { defaultValue: [] });
		resetField('gradeLevel', { defaultValue: '' });
		resetField('languages', { defaultValue: [] });
		resetField('transportation', { defaultValue: '' });
		resetField('minTuition', { defaultValue: '' });
		resetField('maxTuition', { defaultValue: '' });
		resetField('beforeSchoolCare', { defaultValue: false });
		resetField('afterSchoolCare', { defaultValue: false });
		resetField('uniforms', { defaultValue: false });
		resetField('freeOnly', { defaultValue: false });
		handleSubmit(onSubmit)();
	};

	return (
		<div
			className={classnames({
				'is-scrolled-to-end': isMobile(windowWidth) && resetInView,
				'is-visible': showMobileFilters,
				desktop: !isMobile(windowWidth),
				filters: true,
				mobile: isMobile(windowWidth),
			})}
			style={styles}
		>
			<div className="form-wrapper">
				<form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
					<fieldset ref={addressRef}>
						<Controller
							{...defaultAddressValue}
							name="address"
							control={control}
							rules={{ required: true }}
							render={({ field, fieldState }) => (
								<label htmlFor="address">
									<span className="sr-only">
										{translate('Your address', 'Su dirección', 'Ciwaankaaga')}
									</span>
									<SearchAddress
										field={field}
										fieldState={fieldState}
										isHovering={isHovering}
									/>
								</label>
							)}
						/>
						{isValidAddress && <span className="is-valid">Valid address</span>}
						{errors.address && <span className="is-required">This field is required</span>}
					</fieldset>
					<fieldset>
						<Controller
							control={control}
							defaultValue={defaultGradeLevel || ''}
							name="gradeLevel"
							render={({ field: { onChange, value, ref } }) => (
								<label htmlFor="msf-hpf-grade-level">
									<span className="sr-only">
										{translate('Choose grades', 'Escoja los grados', 'Dooro fasalada')}
									</span>
									<Select
										className={classnames({
											'has-selection': !!value,
											'grade-select-wrapper': true,
										})}
										inputRef={ref}
										isSearchable={false}
										onChange={(val) => onChange(val.value)}
										options={gradeOptions}
										placeholder={translate('All grades', 'Todas las calificaciones', 'Fasalada oo dhan')}
										styles={dropdownStyles}
										value={find({ value }, gradeOptions) || ''}
									/>
								</label>
							)}
						/>
					</fieldset>
					<fieldset>
						<Controller
							control={control}
							defaultValue={defaultTransportation || ''}
							name="transportation"
							render={({ field: { onChange, value, ref } }) => (
								<label htmlFor="msf-hpf-transportation">
									<span className="sr-only">
										{translate('Transportation', 'Todos los transportes', 'Dhammaan gaadiidka')}
									</span>
									<Select
										className={classnames({
											'has-selection': !!value,
											'transportation-select-wrapper': true,
										})}
										inputRef={ref}
										isSearchable={false}
										onChange={(val) => onChange(val.value)}
										options={transportationOptions}
										placeholder={translate('Transportation', 'Todos los transportes', 'Dhammaan gaadiidka')}
										styles={dropdownStyles}
										value={find({ value }, transportationOptions) || ''}
									/>
								</label>
							)}
						/>
					</fieldset>
					<MoreFilters
						control={control}
						handleSubmit={handleSubmit}
						onSubmit={onSubmit}
						register={register}
						resetField={resetField}
						resetFilters={resetFilters}
						watch={watch}
					/>
					<button type="submit">
						{translate('Filter', 'Filtrar', 'Baar')}
					</button>
				</form>
				<SchoolSelector />
				{isMobile(windowWidth) && (
					<button
						className="reset-all-filters-mobile"
						onClick={resetFilters}
						type="button"
						ref={resetRef}
					>
						Reset all Filters
					</button>
				)}
			</div>
		</div>
	);
};

SearchFilters.propTypes = {
	containerRef: PropTypes.shape({
		current: PropTypes.shape({
			getBoundingClientRect: PropTypes.func,
		}),
	}).isRequired,
};

export default SearchFilters;
