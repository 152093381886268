/**
 * Helper function for filtering by the selected extracurricular selections.
 */
import filter from 'lodash/fp/filter';
import get from 'lodash/fp/get';
import reduce from 'lodash/reduce';
import { createSelector } from 'reselect';

const filterByExtracurriculars = createSelector(
	get('schools'),
	get('extracurriculars'),
	(schools, extracurriculars) => {
		if (extracurriculars && extracurriculars.length) {
			return filter((school) => {
				if (!school.aboutExtracurricularsOffered
					|| !school.aboutExtracurricularsOffered.length) return false;
				return reduce(extracurriculars, (isMatch, extracurricular) => {
					if (isMatch) return isMatch;
					return school.aboutExtracurricularsOffered.includes(extracurricular);
				}, false);
			}, schools);
		}
		return schools;
	},
);

export default filterByExtracurriculars;
