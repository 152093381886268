/* global window, google */
import axios from 'axios';
import find from 'lodash/fp/find';
import humps from 'lodash-humps';
import React, { useEffect, useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { GoogleMap } from '@react-google-maps/api';
import { useWindowHeight } from '@react-hook/window-size';

import mapStyles from './lib/mapStyles';
import Modal from './components/Modal';
import toggleOverlay from './lib/toggleOverlay';
import translate from './lib/translate';

const BusMap = () => {
	const [schools, setSchools] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const windowHeight = useWindowHeight({ leading: true });
	useEffect(() => {
		axios.get(`${translate('/', '/es/', '/so/')}wp-json/schools/v1/all`)
			.then((response) => {
				setSchools(humps(response.data));
			});
	}, []);
	const ref = useOnclickOutside(() => {
		setShowModal(false);
		toggleOverlay(false);
	});
	const [postName] = window.location.pathname.match(/([^/]+)[/]?$/).slice(-1);
	let school;
	if (schools.length) school = find({ postName }, schools);
	if (!school || !school.shapeData) return null;
	return (
		<div>
			{showModal ? (
				<Modal>
					<div className="busmap-modal">
						<div className="busmap-wrapper" ref={ref}>
							<button
								type="button"
								className="close"
								onClick={() => {
									setShowModal(false);
									toggleOverlay(false);
								}}
							>
								{translate('Close', 'Cerrar', 'Xir')}
							</button>
							<h2>
								{translate(
									'Transportation map for',
									'Mapa de transporte para',
									'Khariidada gaadidka ee',
								)}<br />
								{school.postTitle}
							</h2>
							<div>
								<GoogleMap
									center={{ lat: 44.9778, lng: -93.2650 }}
									onLoad={(instance) => {
										instance.data.addGeoJson(JSON.parse(school.shapeData));
									}}
									mapContainerStyle={{
										width: '100%',
										height: (windowHeight > 600) ? 600 : windowHeight,
									}}
									options={{
										disableDefaultUI: true,
										mapTypeControlOptions: {
											mapTypeIds: [google.maps.MapTypeId.ROADMAP],
										},
										scrollwheel: false,
										zoomControl: true,
										styles: mapStyles,
									}}
									zoom={10}
								/>
							</div>
						</div>
					</div>
				</Modal>
			) : (
				<button
					type="button"
					className="where-bus-goes"
					onClick={() => {
						setShowModal(true);
						toggleOverlay(true);
					}}
				>
					{translate('Where the bus goes', 'Destinos del autobús', 'Meesha baska aado')}
				</button>
			)}
		</div>
	);
};

export default BusMap;
