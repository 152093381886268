/**
 * Helper function for filtering by the need for transportation.
 */
import { createSelector } from 'reselect';
import get from 'lodash/fp/get';
import filter from 'lodash/fp/filter';

const filterByTransportation = createSelector(
	get('schools'),
	get('transportation'),
	(schools, transportation) => {
		if (transportation === 'yes') {
			return filter({ transportationAvailable: true }, schools);
		}
		return schools;
	},
);

export default filterByTransportation;
