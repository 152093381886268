/* eslint-disable no-tabs, indent */
export default {
	control: (provided, state) => ({
		...provided,
		border: 0,
		boxShadow: 0,
		cursor: 'pointer',
		svg: {
			fill: '#2a354a',
			transform: state.menuIsOpen && 'rotate(180deg)',
			transformOrigin: 'center',
		},
		'&:hover': {
			div: {
				color: '#2BB4B3',
				textDecoration: 'underline',
			},
			svg: {
				fill: '#2BB4B3',
			},
		},
	}),
	dropdownIndicator: provided => ({
		...provided,
		padding: 0,
	}),
	indicatorSeparator: () => ({ display: 'none' }),
	menu: provided => ({
		...provided,
		borderRadius: 0,
		marginBottom: 0,
		marginTop: 0,
		width: 150,
	}),
	option: provided => ({
		...provided,
		'&:hover': { color: '#2BB4B3' },
		background: 'transparent',
		color: '#2a354a',
		cursor: 'pointer',
	}),
	singleValue: provided => ({
		...provided,
		padding: 0,
	}),
	valueContainer: provided => ({
		...provided,
		padding: 0,
	}),
};
