/**
 * Helper function for filtering by the selected language selections.
 */
import filter from 'lodash/fp/filter';
import get from 'lodash/fp/get';
import reduce from 'lodash/reduce';
import { createSelector } from 'reselect';

const filterByLanguages = createSelector(
	get('schools'),
	get('languages'),
	(schools, languages) => {
		if (languages && languages.length) {
			return filter((school) => {
				if (!school.languagesSpokenBySchoolStaff
					|| !school.languagesSpokenBySchoolStaff.length) return false;
				return reduce(languages, (isMatch, language) => {
					if (isMatch) return isMatch;
					return school.languagesSpokenBySchoolStaff.includes(language);
				}, false);
			}, schools);
		}
		return schools;
	},
);

export default filterByLanguages;
