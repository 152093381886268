/**
 * Pagination for the list
 */

/* eslint-disable no-tabs, indent, react/jsx-filename-extension, react/jsx-indent,
	react/jsx-indent-props */
import React from 'react';
import PropTypes from 'prop-types';
import SmoothScroll from 'smooth-scroll';
import useStore from '../lib/useStore';

const SearchPagination = ({ resultCount, containerRef }) => {
	const {
		itemsPerPage,
		page,
		setPage,
	} = useStore();
	const pages = Math.ceil(resultCount / itemsPerPage);
	const firstPager = [];
	const secondPager = [];
	// Scroll to the top of the search and set the page index
	const changeTo = (p) => {
		const scroll = new SmoothScroll();
		if (containerRef && containerRef.current) {
			scroll.animateScroll(containerRef.current, null, {
				durationMax: 100,
				speed: 100,
				speedAsDuration: true,
			});
		}
		setPage(p);
	};
	// Previous button
	firstPager.push((
		<li key="pager-prev" className="previous-page">
			<button
				disabled={page <= 1}
				onClick={() => changeTo(page - 1)}
				type="button"
			>
				&lt;
			</button>
		</li>
	));
	// Page index buttons up to the current page
	for (let i = 1; i < page; i++) {
		firstPager.push((
			<li key={`pager-${i}`} className="select-page">
				<button type="button" onClick={() => changeTo(i)}>{i}</button>
			</li>
		));
	}
	// The current page
	firstPager.push((
		<li key="pager-current" className="current-page">
			<button type="button" disabled>{page}</button>
		</li>
	));
	// Page index buttons after the current page
	for (let i = page + 1; i <= pages; i++) {
		secondPager.push((
			<li key={`pager-${i}`} className="select-page">
				<button type="button" onClick={() => changeTo(i)}>{i}</button>
			</li>
		));
	}
	// Next button
	secondPager.push((
		<li key="pager-next" className="next-page">
			<button
				disabled={page >= pages}
				onClick={() => changeTo(page + 1)}
				type="button"
			>
				&gt;
			</button>
		</li>
	));
	// If there are more than five pages, hide some of the index buttons.
	if (pages > 5) {
		if (page > 3) {
			firstPager.splice(1, page - 3, <li key="pager-spacer-left">...</li>);
		}
		if (pages - page > 2) {
			secondPager.splice(2, secondPager.length - 3, <li key="page-spacer-right">...</li>);
		}
	}
	return (
		<ul className="serach-pagination">
			{[...firstPager, ...secondPager]}
		</ul>
	);
};

SearchPagination.propTypes = {
	resultCount: PropTypes.number.isRequired,
	containerRef: PropTypes.shape({
		current: PropTypes.shape({}),
	}),
};

SearchPagination.defaultProps = {
	containerRef: null,
};

export default SearchPagination;
