/**
 * Helper function for filtering by care options.
 */
import { createSelector } from 'reselect';
import get from 'lodash/fp/get';
import filter from 'lodash/fp/filter';

/* eslint-disable no-else-return */
const filterByCost = createSelector(
	get('schools'),
	get('afterSchoolCare'),
	get('beforeSchoolCare'),
	(schools, afterSchoolCare, beforeSchoolCare) => {
		if (beforeSchoolCare || afterSchoolCare) {
			if (beforeSchoolCare && afterSchoolCare) {
				return filter((school) => school.beforeSchoolCare && school.afterSchoolCare, schools);
			}	else if (beforeSchoolCare) {
				return filter((school) => school.beforeSchoolCare, schools);
			}	else if (afterSchoolCare) {
				return filter((school) => school.afterSchoolCare, schools);
			}
		}
		return schools;
	},
);

export default filterByCost;
