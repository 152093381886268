import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ShortUniqueId from 'short-unique-id';
import kebabCase from 'lodash/kebabCase';

import extracurricularOptions from '../lib/extracurricularOptions';
import useStore from '../lib/useStore';

const ExtracurricularsFilter = ({ initialChoices, register }) => {
	const uuid = new ShortUniqueId({ length: 28, dictionary: 'alpha' });
	const {
		showMoreExtracurriculars,
		toggleShowMoreExtracurriculars,
	} = useStore();
	let displayedExtracurriculars = extracurricularOptions;
	if (!showMoreExtracurriculars) {
		displayedExtracurriculars = extracurricularOptions.slice(0, initialChoices);
	}
	return (
		<fieldset className="more-filters-extracurriculars">
			<legend>Extracurriculars</legend>
			{displayedExtracurriculars.map((extracurricular) => {
				const id = `extracurricular-${uuid.rnd()}`;
				return (
					<label className={`extracurricular-${kebabCase(extracurricular)}`} htmlFor={id} key={id}>
						<span>
							<input
								id={id}
								type="checkbox"
								value={extracurricular}
								{...register('extracurriculars')}
							/>
							{extracurricular}
						</span>
					</label>
				);
			})}
			<button
				className={classnames({
					'see-more': !showMoreExtracurriculars,
					'see-less': showMoreExtracurriculars,
				})}
				onClick={toggleShowMoreExtracurriculars}
				type="button"
			>
				{showMoreExtracurriculars ? 'See less' : 'See more'}
			</button>
		</fieldset>
	);
};

ExtracurricularsFilter.propTypes = {
	initialChoices: PropTypes.number.isRequired,
	register: PropTypes.func.isRequired,
};

export default ExtracurricularsFilter;
