/**
 * Set some body styles to help with the search filters overlay
 */

/* eslint-disable no-tabs, indent */
/* global document, window */
const toggleOverlay = (overlay) => {
	if (overlay) {
		document.body.style.top = `-${window.scrollY}px`;
		document.body.style.position = 'fixed';
		document.body.style.overflow = 'hidden';
	} else if (document.body.style.position === 'fixed') {
		document.body.style.position = '';
		document.body.style.overflow = '';
		document.documentElement.style.scrollBehavior = 'auto';
		const yPos = Math.abs(parseInt(document.body.style.top, 10));
		window.scrollTo(0, yPos, { duration: 0 });
		document.documentElement.style.scrollBehavior = 'smooth';
		document.body.style.top = '';
	}
};

export default toggleOverlay;
