/**
 * Sort schools by proximity or alpha
 */

/* eslint-disable no-tabs, indent */
import { createSelector } from 'reselect';
import get from 'lodash/fp/get';
import orderBy from 'lodash/fp/orderBy';

const sortSchools = createSelector(
	get('schools'),
	get('sort'),
	(schools, sort) => {
		if (sort === 'proximity') {
			return orderBy(['distance', 'postTitle'], ['asc', 'asc'], schools);
		} else if (sort === 'score') {
			return orderBy(['scorePercent', 'postTitle'], ['desc', 'asc'], schools);
		}
		return schools;
	},
);

export default sortSchools;
