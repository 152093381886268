// import translate from './translate';
import getSite from './getSite';

let extracurricularOptions = [
	'Cultural Extracurriculars',
	'Debate/Speech Clubs',
	'Environmental/Outdoors Clubs',
	'Foreign Language Clubs',
	'Journalism/Newspaper Club',
	'Music',
	'Sports',
	'STEM/STEAM Clubs',
	'Theater',
	'Visual Arts',
];

if (getSite() === 2) {
	extracurricularOptions = [
		'Actividades culturales extracurriculares',
		'Clubes de debate y oratoria',
		'Clubes medioambientales y al aire libre',
		'Clubes de idiomas extranjeros',
		'Club de periodismo o periódicos',
		'Música',
		'Deportes',
		'Clubes STEM/STEAM',
		'Teatro',
		'Artes visuales',
	];
}

if (getSite() === 3) {
	extracurricularOptions = [
		'Dhaqanka Manhajka Dheeraadka ka ah',
		'Naadiyada Doodaha/Khudbadaha',
		'Naadiyada Deegaanka/Dibada',
		'Naadiyada Luuqadaha Qalaad',
		'Naadiga Saxaafada/Wargeysyada',
		'Muusiga',
		'Ciyaaraha',
		'Naadiyada STEM/STEAM',
		'Masraxa',
		'Farshaxanka Muuqda',
	];
}

export default extracurricularOptions;
