import PropTypes from 'prop-types';
import React from 'react';
import Toggle from 'react-toggle';
import { Controller } from 'react-hook-form';

import useStore from '../lib/useStore';

const BooleanFilters = ({ control, watch }) => {
	const {
		afterSchoolCare,
		beforeSchoolCare,
		uniforms,
	} = useStore();
	const beforeSchoolCareFormVal = watch('beforeSchoolCare');
	const afterSchoolCareFormVal = watch('afterSchoolCare');
	const uniformsFormVal = watch('uniforms');
	return (
		<>
			<Controller
				name="beforeSchoolCare"
				control={control}
				defaultValue={beforeSchoolCare}
				render={({ field: { onChange } }) => (
					<label htmlFor="more-filters-before-school-care">
						<Toggle
							checked={beforeSchoolCareFormVal}
							icons={false}
							id="more-filters-before-school-care"
							onChange={(e) => onChange(e.target.checked)}
						/>
						Before School Care Offered
					</label>
				)}
			/>
			<Controller
				name="afterSchoolCare"
				control={control}
				defaultValue={afterSchoolCare}
				render={({ field: { onChange } }) => (
					<label htmlFor="more-filters-after-school-care">
						<Toggle
							checked={afterSchoolCareFormVal}
							icons={false}
							id="more-filters-after-school-care"
							onChange={(e) => onChange(e.target.checked)}
						/>
						After School Care Offered
					</label>
				)}
			/>
			<Controller
				name="uniforms"
				control={control}
				defaultValue={uniforms}
				render={({ field: { onChange } }) => (
					<label htmlFor="more-filters-uniforms">
						<Toggle
							checked={uniformsFormVal}
							icons={false}
							id="more-filters-uniforms"
							onChange={(e) => onChange(e.target.checked)}
						/>
						Uniforms Required
					</label>
				)}
			/>
		</>
	);
};

BooleanFilters.propTypes = {
	control: PropTypes.shape({}).isRequired,
	watch: PropTypes.func.isRequired,
};

export default BooleanFilters;
