import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useWindowSize } from '@react-hook/window-size';
import 'react-toggle/style.css';

import AcademicProgramsOfferedFilter from './AcademicProgramsOfferedFilter';
import BooleanFilters from './BooleanFilters';
import CostFilter from './CostFilter';
import ExtracurricularsFilter from './ExtracurricularsFilter';
import isMobile from '../lib/isMobile';
import LanguageFilter from './LanguageFilter';
import useStore from '../lib/useStore';

/* eslint-disable arrow-body-style */
const MoreFilters = ({
	control,
	handleSubmit,
	onSubmit,
	register,
	watch,
	resetFilters,
}) => {
	const {
		academicProgramsOffered,
		afterSchoolCare,
		beforeSchoolCare,
		extracurriculars,
		languages,
		maxTuition,
		minTuition,
		showMoreFilters,
		toggleMoreFilters,
		uniforms,
	} = useStore();
	const [windowWidth] = useWindowSize({ leading: true });
	let activeFilters = languages.length + extracurriculars.length
		+ academicProgramsOffered.length;
	if (maxTuition) activeFilters += 1;
	if (minTuition) activeFilters += 1;
	if (beforeSchoolCare) activeFilters += 1;
	if (afterSchoolCare) activeFilters += 1;
	if (uniforms) activeFilters += 1;

	const initialChoices = isMobile(windowWidth) ? 3 : 4;
	return (
		<fieldset
			className={classnames({
				'more-filters': true,
				'is-mobile': isMobile(windowWidth),
				'is-desktop': !isMobile(windowWidth),
			})}
		>
			{!isMobile(windowWidth) && (
				<button
					className={classnames({
						'expand-more-filters': true,
						'has-active-filters': activeFilters > 0,
						'is-expanded': showMoreFilters,
						'is-not-expanded': !showMoreFilters,
					})}
					onClick={toggleMoreFilters}
					type="button"
				>
					More {activeFilters ? `(${activeFilters})` : null}
				</button>
			)}
			{(showMoreFilters || isMobile(windowWidth)) && (
				<div className="more-filters-inner">
					{!isMobile(windowWidth) && <div className="more-filters-label">More Filters</div>}
					<div className="more-filters-inner-scroll">
						<LanguageFilter register={register} initialChoices={initialChoices} />
						<AcademicProgramsOfferedFilter register={register} initialChoices={initialChoices} />
						<ExtracurricularsFilter register={register} initialChoices={initialChoices} />
						<BooleanFilters control={control} watch={watch} />
						<CostFilter register={register} control={control} watch={watch} />
					</div>
					{!isMobile(windowWidth) && (
						<div className="form-actions">
							<button className="reset-filters" type="button" onClick={resetFilters}>
								Reset all filters
							</button>
							<button className="submit-filters" type="button" onClick={handleSubmit(onSubmit)}>
								Apply filters
							</button>
						</div>
					)}
				</div>
			)}
		</fieldset>
	);
};

MoreFilters.propTypes = {
	control: PropTypes.shape({}).isRequired,
	handleSubmit: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	register: PropTypes.func.isRequired,
	resetFilters: PropTypes.func.isRequired,
	watch: PropTypes.func.isRequired,
};

export default MoreFilters;
