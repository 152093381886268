/* eslint-disable no-tabs, indent, react/jsx-filename-extension, react/jsx-indent,
	react/jsx-indent-props */
import axios from 'axios';
import React, { useEffect, useRef } from 'react';
import { useWindowWidth } from '@react-hook/window-size';

import isMobile from './lib/isMobile';
import SearchFilters from './components/SearchFilters';
import SearchFilterToggle from './components/SearchFilterToggle';
import SearchLoading from './components/SearchLoading';
import SearchMap from './components/SearchMap';
import SearchResults from './components/SearchResults';
import SearchToggle from './components/SearchToggle';
import useStore from './lib/useStore';
import translate from './lib/translate';

const Search = () => {
	const setSchools = useStore((state) => state.setSchools);
	const isLoading = useStore((state) => state.isLoading);
	const activeMobileTab = useStore((state) => state.activeMobileTab);
	const width = useWindowWidth();
	const container = useRef(null);
	const resultsMapRef = useRef(null);
	useEffect(() => {
		axios.get(`${translate('/', '/es/', '/so/')}wp-json/schools/v1/all`)
			.then((response) => {
				setSchools(response.data);
			});
	}, []);
	return (
		<div
			className="msf-school-finder"
			ref={container}
			style={{ position: 'relative' }}
		>
			{isMobile(width) ? <SearchFilterToggle /> : null}
			<SearchFilters containerRef={container} />
			{isMobile(width) ? <SearchToggle resultsMapRef={resultsMapRef} /> : null}
			{isLoading && <SearchLoading />}
			<div className="results-wrapper">
				{(!isMobile(width) || activeMobileTab === 'list') ? (
					<SearchResults containerRef={container} />
				) : null}
				{(!isMobile(width) || activeMobileTab === 'map') ? (
					<SearchMap resultsMapRef={resultsMapRef} />
				) : null}
			</div>
		</div>
	);
};

export default Search;
