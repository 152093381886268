import classnames from 'classnames';
import kebabCase from 'lodash/kebabCase';
import PropTypes from 'prop-types';
import React from 'react';
import ShortUniqueId from 'short-unique-id';

import languageOptions from '../lib/languageOptions';
import useStore from '../lib/useStore';

const LanguageFilter = ({ initialChoices, register }) => {
	const uuid = new ShortUniqueId({ length: 28, dictionary: 'alpha' });
	const {
		showMoreLanguages,
		toggleShowMoreLanguages,
	} = useStore();
	let displayedLanguages = languageOptions;
	if (!showMoreLanguages) displayedLanguages = languageOptions.slice(0, initialChoices);
	return (
		<fieldset className="more-filters-language">
			<legend>Languages Spoken</legend>
			{displayedLanguages.map((language) => {
				const id = `language-${uuid.rnd()}`;
				return (
					<label className={`language-${kebabCase(language)}`} htmlFor={id} key={id}>
						<span>
							<input
								id={id}
								type="checkbox"
								value={language}
								{...register('languages')}
							/>
							{language}
						</span>
					</label>
				);
			})}
			<button
				className={classnames({
					'see-more': !showMoreLanguages,
					'see-less': showMoreLanguages,
				})}
				onClick={toggleShowMoreLanguages}
				type="button"
			>
				{showMoreLanguages ? 'See less' : 'See more'}
			</button>
		</fieldset>
	);
};

LanguageFilter.propTypes = {
	initialChoices: PropTypes.number.isRequired,
	register: PropTypes.func.isRequired,
};

export default LanguageFilter;
