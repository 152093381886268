/**
 * Helper function to calculate the distance of the address from each school, as
 * well as determine whether the address falls within an optionally provided
 * geojson polygon.
 */
import get from 'lodash/fp/get';
import map from 'lodash/fp/map';
import { createSelector } from 'reselect';
import { isPointInPolygon, getDistance } from 'geolib';

const appendGeo = createSelector(
	get('schools'),
	get('addressCoordinates'),
	(schools, coordinates) => {
		if (coordinates) {
			return map((school) => {
				let processed = {
					...school,
					transportationAvailable: false,
					distance: 10000000,
				};
				const { shapeData, coordinates: schoolCoordinates } = school;
				if (coordinates && coordinates.lat && coordinates.lng) {
					if (shapeData) {
						let shapeObject;
						try {
							shapeObject = JSON.parse(shapeData);
						} catch (e) {
							console.warn(`${school.postTitle} has bad JSON for shape data`);
						}
						// Making an assumption about the path to the polygon
						// @TODO: something else? hope for the best?
						const polygon = get('features.0.geometry.coordinates.0', shapeObject);
						const { lat, lng } = coordinates;
						try {
							if (polygon) {
								const transportationAvailable = isPointInPolygon(
									{ latitude: lat, longitude: lng },
									map((points) => ({
										latitude: points[1],
										longitude: points[0],
									}), polygon),
								);
								processed = {
									...school,
									transportationAvailable,
								};
							}
						} catch (e) {
							console.warn(`${school.postTitle} has unusable shape data`);
						}
					}
					if (schoolCoordinates) {
						// Determine the distance between the provided address
						// and the school.
						const distance = getDistance(
							{ latitude: coordinates.lat, longitude: coordinates.lng },
							{ latitude: schoolCoordinates.lat, longitude: schoolCoordinates.lng },
						);
						processed = {
							...processed,
							distance: parseInt(distance * 0.00621, 10) / 10,
						};
					}
				}
				return processed;
			}, schools);
		}
		return schools;
	},
);

export default appendGeo;
