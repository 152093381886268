/**
 * Helper function for filtering by cost.
 */
import { createSelector } from 'reselect';
import get from 'lodash/fp/get';
import filter from 'lodash/fp/filter';
import toNumber from 'lodash/toNumber';

/* eslint-disable no-else-return */
const filterByCost = createSelector(
	get('schools'),
	get('minTuition'),
	get('maxTuition'),
	get('freeOnly'),
	(schools, minTuition, maxTuition, freeOnly) => {
		if (freeOnly) {
			return filter((school) => school.introCostOfTuition === 0, schools);
		}
		if (minTuition || maxTuition) {
			if (minTuition && maxTuition) {
				const start = toNumber(minTuition);
				const end = toNumber(maxTuition);
				return filter((school) => (
					school.introCostOfTuition >= start && school.introCostOfTuition <= end
				), schools);
			}	else if (minTuition) {
				const start = toNumber(minTuition);
				return filter((school) => school.introCostOfTuition >= start, schools);
			}	else if (maxTuition) {
				const end = toNumber(maxTuition);
				return filter((school) => school.introCostOfTuition <= end, schools);
			}
		}
		return schools;
	},
);

export default filterByCost;
