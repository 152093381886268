/**
 * Helper function for filtering by the selected academic program selections.
 */
import filter from 'lodash/fp/filter';
import get from 'lodash/fp/get';
import reduce from 'lodash/reduce';
import { createSelector } from 'reselect';

const filterByPrograms = createSelector(
	get('schools'),
	get('academicProgramsOffered'),
	(schools, programs) => {
		if (programs && programs.length) {
			return filter((school) => {
				if (!school.academicsAcademicProgramsOffered
					|| !school.academicsAcademicProgramsOffered.length) return false;
				return reduce(programs, (isMatch, language) => {
					if (isMatch) return isMatch;
					return school.academicsAcademicProgramsOffered.includes(language);
				}, false);
			}, schools);
		}
		return schools;
	},
);

export default filterByPrograms;
