/* global window */
import classnames from 'classnames';
import find from 'lodash/fp/find';
import identity from 'lodash/identity';
import pickBy from 'lodash/fp/pickBy';
import React, { useState, createRef } from 'react';
import Select from 'react-select';
import useHover from '@react-hook/hover';
import { getGeocode, getLatLng } from 'use-places-autocomplete';
import { useForm, Controller } from 'react-hook-form';

import Address from './components/Address';
import dropdownStyles from './lib/dropdownStyles';
import gradeOptions from './lib/gradeOptions';
import SchoolSelector from './components/SchoolSelector';
import translate from './lib/translate';

const HomepageForm = () => {
	// React hook form
	const {
		control,
		formState: { errors },
		handleSubmit,
	} = useForm();

	// Get the school names from the API to populate typeahead field.
	const [coordinates, setCoordinates] = useState();

	const onSubmit = (res) => {
		let query = pickBy(identity, res);
		query = pickBy((v) => v !== 'all', query);
		if (coordinates) {
			query = {
				transportation: 'yes',
				...coordinates,
				...query,
			};
			const params = new URLSearchParams(query);
			window.location.href = `${translate('/', '/es/', '/so/')}school-profile?${params}`;
		}	else {
			getGeocode({ address: res.address })
				.then((results) => {
					const { lat, lng } = getLatLng(results[0]);
					if (lat && lng) {
						query = {
							lat,
							lng,
							transportation: 'yes',
							...query,
						};
					}
				})
				.catch(() => {})
				.finally(() => {
					const params = new URLSearchParams(query);
					window.location.href = `/search?${params}`;
				});
		}
	};

	const addressRef = createRef(null);
	const isHovering = useHover(addressRef);

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
				<fieldset ref={addressRef}>
					<Controller
						name="address"
						control={control}
						rules={{ required: true }}
						render={({ field, fieldState }) => (
							<label htmlFor="msf-hpf-address">
								<span className="sr-only">
									{translate('Address', 'Su dirección', 'Ciwaankaaga')}
								</span>
								<Address
									field={field}
									fieldState={fieldState}
									isHovering={isHovering}
									setCoordinates={setCoordinates}
								/>
							</label>
						)}
					/>
					{errors.address && (
						<span>
							{translate(
								'This field is required',
								'Esto es requerido',
								'Meeshan/goobtan wa loo bahan yaha',
							)}
						</span>
					)}
				</fieldset>
				<fieldset>
					<Controller
						control={control}
						defaultValue=""
						name="gradeLevel"
						rules={{ required: true }}
						render={({ field: { onChange, value, ref } }) => (
							<label htmlFor="msf-hpf-grade-level">
								<span className="sr-only">
									{translate('Choose grades', 'Escoja los grados', 'Dooro fasalada')}
								</span>
								<Select
									className={classnames({ 'has-selection': !!value })}
									inputRef={ref}
									isSearchable={false}
									onChange={(val) => onChange(val.value)}
									options={gradeOptions}
									placeholder={translate('Choose grades', 'Escoja los grados', 'Dooro fasalada')}
									styles={dropdownStyles}
									value={find({ value }, gradeOptions)}
								/>
							</label>
						)}
					/>
					{errors.gradeLevel && (
						<span>
							{translate(
								'This field is required',
								'Esto es requerido',
								'Meeshan/goobtan wa loo bahan yaha',
							)}
						</span>
					)}
				</fieldset>
				<button type="submit">
					{translate('Search', 'Buscar', 'Raadi')}
				</button>
			</form>
			<SchoolSelector />
		</>
	);
};

export default HomepageForm;
