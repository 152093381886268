/**
 * Selects state values to turn into url parameters.
 */
import pickBy from 'lodash/fp/pickBy';
import isArray from 'lodash/isArray';
import updateUrl from './updateUrl';

const setUrlParams = (get) => {
	const {
		academicProgramsOffered,
		activeMobileTab,
		address,
		addressCoordinates,
		afterSchoolCare,
		beforeSchoolCare,
		extracurriculars,
		freeOnly,
		gradeLevel,
		languages,
		maxTuition,
		minTuition,
		page,
		sort,
		transportation,
		uniforms,
	} = get();
	let urlParams = {};
	if (addressCoordinates) {
		urlParams = {
			...urlParams,
			...addressCoordinates,
		};
	}
	urlParams = {
		...urlParams,
		academicProgramsOffered,
		activeMobileTab,
		address,
		afterSchoolCare,
		beforeSchoolCare,
		currentPage: page,
		extracurriculars,
		freeOnly,
		gradeLevel,
		languages,
		maxTuition,
		minTuition,
		sort,
		transportation,
		uniforms,
	};
	urlParams = pickBy((v) => (isArray(v) ? !!v.length : !!v), urlParams);
	updateUrl(urlParams);
};

export default setUrlParams;
