import translate from './translate';

const transportationOptions = [
	{
		value: 'yes',
		label: translate('Transportation available', 'Transporte disponible', 'Gaadiidka diyaara ah'),
	},
	{
		value: 'no',
		label: translate("I don't need transportation", 'No necesito transporte', 'Uma baahni gaadiid'),
	},
];

export default transportationOptions;
