export default {
	control: (provided, state) => ({
		...provided,
		boxShadow: 0,
		cursor: 'pointer',
		height: 60,
		border: '2px solid #bcbcbc',
		borderRadius: 7,
		svg: {
			fill: '#2a354a',
			transform: state.menuIsOpen && 'rotate(180deg)',
			transformOrigin: 'center',
		},
		'&:hover': {
			div: {
				color: '#2BB4B3',
				textDecoration: 'underline',
			},
			svg: {
				fill: '#2BB4B3',
			},
		},
	}),
	indicatorSeparator: () => ({ display: 'none' }),
	menu: (provided) => ({
		...provided,
		border: 0,
		marginTop: 0,
		marginBottom: 0,
	}),
	option: (provided) => ({
		...provided,
		'&:hover': { color: '#2BB4B3' },
		background: 'transparent',
		color: '#2a354a',
		cursor: 'pointer',
	}),
	singleValue: (provided) => ({
		...provided,
	}),
	valueContainer: (provided) => ({
		...provided,
		height: 60,
	}),
};
