import translate from './translate';

const gradeOptions = [
	{ value: 'preschool', label: 'Pre-K' },
	{ value: 'elementary', label: 'K-5' },
	{ value: 'middle', label: '6-8' },
	{ value: 'high', label: '9-12' },
	{
		value: 'all',
		label: translate('All grades', 'Todos los grados', 'Fasalada oo dhan'),
	},
];

export default gradeOptions;
