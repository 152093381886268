// import translate from './translate';

const languageOptions = [
	'American Sign Language',
	'Amharic',
	'Arabic',
	'Aramaic',
	'Cambodian',
	'English',
	'Filipino',
	'French',
	'German',
	'Greek',
	'Hebrew',
	'Hmong',
	'Japanese',
	'Karen',
	'Kayah',
	'Kiswahili',
	'Laotian',
	'Latin',
	'Mandarin Chinese',
	'Oromo',
	'Pashto',
	'Somali',
	'Spanish',
	'Swahili',
	'Ukrainian',
	'Urdu',
	'Vietnamese',
];

export default languageOptions;
