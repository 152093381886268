/**
 * React bootstrap typeahead component for the main search page, controlled by
 * React hook form.
 */

/* eslint-disable no-tabs, indent, react/jsx-filename-extension, react/jsx-indent,
	react/jsx-indent-props, react/prop-types */
import classnames from 'classnames';
import map from 'lodash/fp/map';
import omit from 'lodash/fp/omit';
import React from 'react';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import { Typeahead } from 'react-bootstrap-typeahead';
import useStore from '../lib/useStore';
import translate from '../lib/translate';

const SearchAddress = ({
	field,
	fieldState,
	isHovering,
}) => {
	const {
		ready,
		setValue,
		suggestions: { status, data },
	} = usePlacesAutocomplete({
		requestOptions: {
			locationbias: 'circle:16000@44.9778,-93.2650',
		},
		debounce: 250,
	});
	const {
		defaultAddress,
		setValidAddress,
		isValidAddress,
	} = useStore();

	const onInputChange = (value) => {
		field.onChange(value);
		setValue(value);
		if (isValidAddress) setValidAddress(false);
	};

	// Extend the onChange passed from react-hook-form to also geocode and set
	// a valid address. We want to do this indepenently from form submission
	// so that we can indicate whether the address is valid.
	const onChange = (value) => {
		field.onChange(value);
		if (value && value[0]) {
			getGeocode({ address: value[0] }).then((results) => {
				const { lat, lng } = getLatLng(results[0]);
				setValidAddress(!!(lat && lng));
			}).catch((err) => {
				switch (err) {
					case 'ZERO_RESULTS': {
						setValidAddress(false);
						break;
					}
					default:
						break;
				}
			});
		}
	};

	// Don't display the autocomplete until user has interacted with the input.
	// Since we're getting address autocompletes on the fly it would alway say
	// "No matches found." on initial load otherwise.
	const open = (field.value && field.value.length > 2) ? { } : { open: false };
	const defaultSelected = defaultAddress ? { defaultSelected: [defaultAddress] } : { };

	return (
		<Typeahead
			{...defaultSelected}
			{...omit('onChange', field)}
			{...open}
			aria-describedby="typeaheadError"
			className={classnames({
				'is-invalid': fieldState.invalid,
				'is-hovered': isHovering,
			})}
			clearButton
			disabled={!ready}
			id="address"
			name="address"
			onChange={onChange}
			onInputChange={onInputChange}
			options={status === 'OK' ? map((item) => item.description, data) : []}
			placeholder={translate('Your address', 'Su dirección', 'Ciwaankaaga')}
		/>
	);
};

export default SearchAddress;
