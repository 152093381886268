/**
 * Popup component for the desktop version of the infowindow. This one is an
 * infowindow and is positioned via the associated coordinates.
 */

/* eslint-disable no-tabs, indent, react/jsx-filename-extension, react/jsx-indent,
	react/jsx-indent-props, react/no-danger */
import React from 'react';
import { InfoWindow } from '@react-google-maps/api';
import PropTypes from 'prop-types';

import useStore from '../lib/useStore';
import translate from '../lib/translate';

const SearchPopup = ({ activeSchool }) => {
	const { setActiveMarker } = useStore();
	return (
		<InfoWindow
			key={`infobox-${Date.now()}-${activeSchool.id}`}
			onCloseClick={() => setActiveMarker(null)}
			options={{
				maxWidth: 350,
				disableAutoPan: true,
			}}
			position={{ ...activeSchool.coordinates }}
		>
			<div className="result-info-window">
				<a href={`${translate('/', '/es/', '/so/')}school-profile/${activeSchool.postName}`}>
					<div className="group-left">
						<h2 className="school-title">{activeSchool.postTitle}</h2>
						{activeSchool.subtitle && <div className="school-subtitle">{activeSchool.subtitle}</div>}
						{activeSchool.grades && (
							<div className="school-grades">
								{`${translate('Grades', 'Grados', 'Darajooyin')} ${activeSchool.grades}`}
							</div>
						)}
						{activeSchool.transportationAvailable && (
							<div className="transportation-available">
								<div>
									{translate('Transportation available', 'Transporte disponible', 'Gaadiidka diyaara ah')}
								</div>
							</div>
						)}
						{activeSchool.performanceRating ? (
							<div dangerouslySetInnerHTML={{ __html: activeSchool.performanceRating }} />
						) : null}
					</div>
					<div className="group-right">
						{activeSchool.transportationAvailable && (
							<div className="transportation-available-icons">
								<div>
									<span className="walk-icon" />
									<span className="bus-icon" />
								</div>
							</div>
						)}
					</div>
				</a>
			</div>
		</InfoWindow>
	);
};

SearchPopup.propTypes = {
	activeSchool: PropTypes.shape({
		id: PropTypes.number.isRequired,
		coordinates: PropTypes.shape({
			lat: PropTypes.number,
			lng: PropTypes.number,
		}).isRequired,
		grades: PropTypes.string,
		performanceRating: PropTypes.string,
		postName: PropTypes.string.isRequired,
		postTitle: PropTypes.string.isRequired,
		subtitle: PropTypes.string,
		transportationAvailable: PropTypes.bool,
	}).isRequired,
};

export default SearchPopup;
