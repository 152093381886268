/**
 * Map component for search results. Powered by google maps.
 */

/* eslint-disable no-tabs, indent, react/jsx-filename-extension, react/jsx-indent,
	react/jsx-indent-props */
/* global google */
import filter from 'lodash/fp/filter';
import get from 'lodash/fp/get';
import map from 'lodash/fp/map';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import SmoothScroll from 'smooth-scroll';
import sortBy from 'lodash/fp/sortBy';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { useWindowSize } from '@react-hook/window-size';

import getSchools from '../lib/getSchools';
import isMobile from '../lib/isMobile';
import mapStyles from '../lib/mapStyles';
import markerCurrent from '../lib/markerCurrent';
import markerSelected from '../lib/markerSelected';
import markerUnselected from '../lib/markerUnselected';
import SearchMobilePopup from './SearchMobilePopup';
import SearchPopup from './SearchPopup';
import useStore from '../lib/useStore';

const SearchMap = ({ resultsMapRef }) => {
	let popup;
	let mobilePopup;
	const {
		activeMarker,
		addressCoordinates,
		map: googleMap,
		setActiveMarker,
		setMap,
		sort,
	} = useStore();
	const [windowWidth, windowHeight] = useWindowSize({ leading: true });
	const schools = useStore((state) => getSchools(state));
	const filtered = filter((school) => get('coordinates', school), schools);
	const boundsDefined = !!(googleMap && googleMap.getBounds());
	// When the list of schools changes, check that there are some in view and
	// if not set the bounds to include the nearest school.
	const fitSchool = () => {
		if (schools.length > 0) {
			const [closest] = sortBy(['distance'], schools);
			const [first] = schools;
			const showSchool = sort === 'score' ? first : closest;
			if (googleMap) {
				googleMap.setZoom(14);
				if (addressCoordinates && (addressCoordinates.lat && addressCoordinates.lng)) {
					googleMap.panTo(addressCoordinates);
				}
				if (get('coordinates.lat', showSchool)) {
					const bounds = googleMap.getBounds();
					if (bounds && !bounds.contains(showSchool.coordinates)) {
						bounds.extend(showSchool.coordinates);
						googleMap.fitBounds(bounds);
					}
				}
			}
		}
	};
	useEffect(fitSchool, [schools, boundsDefined]);
	// Array of markers for scools that have coordinates
	const markers = map((school) => (
		<Marker
			key={`marker-${school.id}`}
			position={{ ...school.coordinates }}
			icon={{
				url: (activeMarker && parseInt(activeMarker, 10) === school.id)
					? markerSelected() : markerUnselected(),
			}}
			onClick={() => {
				setActiveMarker(school.id);
				googleMap.panTo(school.coordinates);
				if (isMobile(windowWidth)) {
					const scroll = new SmoothScroll();
					if (resultsMapRef && resultsMapRef.current) {
						scroll.animateScroll(resultsMapRef.current, null, {
							durationMax: 100,
							speed: 100,
							speedAsDuration: true,
						});
					}
				}
			}}
			options={{ optimized: false }}
		/>
	), filtered);
	let center = useStore((state) => state.defaultCoordinates);
	// Add a marker for the search location and set the map center to those
	// coordinates, if they exist.
	if (addressCoordinates && (addressCoordinates.lat && addressCoordinates.lng)) {
		center = addressCoordinates;
		markers.push((
			<Marker
				key="marker-current"
				position={{ ...addressCoordinates }}
				icon={{ url: markerCurrent() }}
				options={{
					optimized: false,
				}}
			/>
		));
	}
	// If a marker is selected, render popups for mobile and desktop. Desktop
	// uses the google maps native infowindow, while the mobile popup is just an
	// absolutely positioned element appearing over the bottom of the map.
	if (activeMarker) {
		const [activeSchool] = filter({ id: parseInt(activeMarker, 10) }, schools);
		if (activeSchool) {
			if (windowWidth > 768) {
				popup = <SearchPopup activeSchool={activeSchool} />;
			}	else {
				mobilePopup = <SearchMobilePopup activeSchool={activeSchool} />;
			}
		}
	}
	return (
		<div className="results-map" ref={resultsMapRef}>
			<GoogleMap
				center={center}
				onLoad={(instance) => {
					setMap(instance);
					setTimeout(fitSchool, 100);
				}}
				mapContainerStyle={{
					width: '100%',
					height: (windowHeight > 800) ? 800 : windowHeight,
				}}
				options={{
					disableDefaultUI: true,
					mapTypeControlOptions: {
						mapTypeIds: [google.maps.MapTypeId.ROADMAP],
					},
					scrollwheel: false,
					zoomControl: true,
					styles: mapStyles,
				}}
				zoom={14}
			>
				{markers}
				{popup}
			</GoogleMap>
			{mobilePopup}
		</div>
	);
};

SearchMap.propTypes = {
	resultsMapRef: PropTypes.shape({
		current: PropTypes.shape({}),
	}),
};

SearchMap.defaultProps = {
	resultsMapRef: null,
};

export default SearchMap;
