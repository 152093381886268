/**
 * Component to toggle mobile search filter visibility.
 */

import React from 'react';
import classnames from 'classnames';

import useStore from '../lib/useStore';
import translate from '../lib/translate';

const SearchFilterToggle = () => {
	const { showMobileFilters, toggleMobileFilters } = useStore();
	return (
		<div
			className={classnames({
				'filter-toggle': true,
				'is-shown': showMobileFilters,
				'is-hidden': !showMobileFilters,
			})}
		>
			<button type="button" onClick={toggleMobileFilters}>
				{showMobileFilters ? translate('Close', 'Cerrar', 'Xir')
					: translate('Filter', 'Filtrar', 'Baar')}
			</button>
		</div>
	);
};

export default SearchFilterToggle;
