/**
 * Base64 url encoded string for the selected marker icon.
 */

/* eslint-disable no-tabs, indent, quotes, arrow-body-style */
const markerSelected = () => {
	return `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='43.2' height='58.317' viewBox='0 0 43.2 58.317'%3E%3Cdefs%3E%3Cfilter id='Path_307' x='0' y='0' width='43.2' height='58.317' filterUnits='userSpaceOnUse'%3E%3CfeOffset dy='3' input='SourceAlpha'/%3E%3CfeGaussianBlur stdDeviation='3' result='blur'/%3E%3CfeFlood flood-opacity='0.38'/%3E%3CfeComposite operator='in' in2='blur'/%3E%3CfeComposite in='SourceGraphic'/%3E%3C/filter%3E%3C/defs%3E%3Cg id='Group_239' data-name='Group 239' transform='translate(-159.9 -90.48)'%3E%3Cg transform='matrix(1, 0, 0, 1, 159.9, 90.48)' filter='url(%23Path_307)'%3E%3Cpath id='Path_307-2' data-name='Path 307' d='M181.5,96.48a12.6,12.6,0,0,0-12.6,12.6h0c0,12.6,12.6,27.717,12.6,27.717s12.6-15.117,12.6-27.717a12.6,12.6,0,0,0-12.6-12.6' transform='translate(-159.9 -90.48)' fill='%2335425c'/%3E%3C/g%3E%3Cpath id='Path_308' data-name='Path 308' d='M181.5,116.639a7.718,7.718,0,1,1,7.718-7.718,7.718,7.718,0,0,1-7.718,7.718h0m-4.88-7.718a4.883,4.883,0,1,1,4.886,4.88,4.883,4.883,0,0,1-4.886-4.88v0' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E`;
};

export default markerSelected;
