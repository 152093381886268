/**
 * Helper function for filtering by uniform requirement.
 */
import { createSelector } from 'reselect';
import get from 'lodash/fp/get';
import filter from 'lodash/fp/filter';

/* eslint-disable no-else-return */
const filterByUniforms = createSelector(
	get('schools'),
	get('uniforms'),
	(schools, uniforms) => {
		if (uniforms) {
			return filter((school) => school.aboutUniforms, schools);
		}
		return schools;
	},
);

export default filterByUniforms;
