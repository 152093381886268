import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ShortUniqueId from 'short-unique-id';
import kebabCase from 'lodash/kebabCase';

import academicProgramsOfferedOptions from '../lib/academicProgramsOfferedOptions';
import useStore from '../lib/useStore';

const LanguageFilter = ({ initialChoices, register }) => {
	const uuid = new ShortUniqueId({ length: 28, dictionary: 'alpha' });
	const {
		showMorePrograms,
		toggleShowMorePrograms,
	} = useStore();
	let displayPrograms = academicProgramsOfferedOptions;
	if (!showMorePrograms) displayPrograms = displayPrograms.slice(0, initialChoices);
	return (
		<fieldset className="more-filters-programs">
			<legend>Academic Programs Offered</legend>
			{displayPrograms.map((program) => {
				const id = `program-${uuid.rnd()}`;
				return (
					<label className={`program-${kebabCase(program)}`} htmlFor={id} key={id}>
						<span>
							<input
								id={id}
								type="checkbox"
								value={program}
								{...register('academicProgramsOffered')}
							/>
							{program}
						</span>
					</label>
				);
			})}
			<button
				className={classnames({
					'see-more': !showMorePrograms,
					'see-less': showMorePrograms,
				})}
				onClick={toggleShowMorePrograms}
				type="button"
			>
				{showMorePrograms ? 'See less' : 'See more'}
			</button>
		</fieldset>
	);
};

LanguageFilter.propTypes = {
	initialChoices: PropTypes.number.isRequired,
	register: PropTypes.func.isRequired,
};

export default LanguageFilter;
