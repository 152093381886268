/**
 * Loading indicator component
 */

/* eslint-disable no-tabs, indent, react/jsx-filename-extension, react/jsx-indent,
	react/jsx-indent-props */
import React from 'react';
import { SpinnerCircularFixed } from 'spinners-react';

const SearchLoading = () => (
	<div className="search-results-loading">
		<SpinnerCircularFixed
			color="#34425c"
			secondaryColor="#d2e357"
			size={90}
			speed={100}
			thickness={200}
		/>
	</div>
);

export default SearchLoading;
