/**
 * Component for the search teaser that appears in the list version of search
 * results.
 */

/* eslint-disable no-tabs, indent, react/jsx-filename-extension, react/jsx-indent,
	react/jsx-indent-props, react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import translate from '../lib/translate';

const SearchResultsTeaser = ({ school }) => (
	<li className="school-teaser" key={school.id}>
		<a href={`${translate('/', '/es/', '/so/')}school-profile/${school.postName}`}>
			<div className="group-wrapper">
				<div className="group-top">
					<h2 className="school-title">{school.postTitle}</h2>
					{school.subtitle && <div className="school-subtitle">{school.subtitle}</div>}
				</div>
				<div className="group-bottom">
					<div className="group-bottom-left">
						{school.grades && (
							<div className="school-grades">
								{`${translate('Grades', 'Grados', 'Darajooyin')} ${school.grades}`}
							</div>
						)}
						{school.topTenSchool && (
							<div className="school-top-ten">
								{translate(
									'A Top Ten Rated School',
									'Una escuela clasificada entre las diez mejores',
									'Loo aqoonsaday toban ka iskuul ee ugu fiican',
								)}
							</div>
						)}
						{school.transportationAvailable && (
							<div className="transportation-available">
								<div>
									{translate('Transportation available', 'Transporte disponible', 'Gaadiidka diyaara ah')}
								</div>
								<div>
									<span className="walk-icon" />
									<span className="bus-icon" />
								</div>
							</div>
						)}
					</div>
					<div className="group-bottom-left">
						{school.performanceRating ? (
							<div dangerouslySetInnerHTML={{ __html: school.performanceRating }} />
						) : null}
					</div>
				</div>
			</div>
		</a>
	</li>
);

SearchResultsTeaser.propTypes = {
	school: PropTypes.shape({
		id: PropTypes.number.isRequired,
		coordinates: PropTypes.shape({
			lat: PropTypes.number,
			lng: PropTypes.number,
		}),
		grades: PropTypes.string,
		performanceRating: PropTypes.string,
		postName: PropTypes.string.isRequired,
		postTitle: PropTypes.string.isRequired,
		subtitle: PropTypes.string,
		topTenSchool: PropTypes.bool,
		transportationAvailable: PropTypes.bool,
	}).isRequired,
};

export default SearchResultsTeaser;
