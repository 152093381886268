// import translate from './translate';
import getSite from './getSite';

let academicProgramsOffered = [
	'Arts Focused Programming',
	'Advanced Placement (AP)',
	'Career & Technical Education (CTE)',
	'Credit Recovery',
	'College Prep',
	'International Baccalaureate (IB)',
	'Language Immersion',
	'Montessori',
	'Multi-Grade Classrooms',
	'Personalized Learning',
	'Professional Internships and/or Job Shadowing',
	'PSEO',
	'STEM/STEAM',
];

if (getSite() === 2) {
	academicProgramsOffered = [
		'Programación centrada en las artes',
		'Colocación avanzada (AP)',
		'Educación profesional y técnica (CTE)',
		'Recuperación de créditos',
		'Preparación universitaria',
		'Bachillerato internacional (IB)',
		'Inmersión lingüística',
		'Montessori',
		'Aulas multigrados',
		'Pasantías profesionales u observación laboral',
		'PSEO',
		'STEM/STEAM',
	];
}

if (getSite() === 3) {
	academicProgramsOffered = [
		'Barnaamikka farshaxanka kusaleysan',
		'Meelaynta Sare (AP)',
		'Waxbarashada Xirfada & Farsamada (CTE)',
		'Buundo Kabashada',
		'Diyaargarowga Kulleejka',
		'International Baccalaureate (IB)',
		'Iskudhafka Luuqada',
		'Montessori',
		'Fasalo Heerar Badan ah',
		'Carbiska-shaqo iyo/ama La Joogga Shaqo Qabashada',
		'PSEO',
		'STEM/STEAM',
	];
}

export default academicProgramsOffered;
