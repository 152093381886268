/* global document */
import { createPortal } from 'react-dom';

const Modal = ({ children }) => {
	let container = document.getElementById('modal-container');
	if (!container) {
		container = document.createElement('div');
		container.setAttribute('id', 'modal-container');
		document.body.appendChild(container);
	}
	return createPortal(children, container);
};

export default Modal;
