import React, { useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { PropTypes } from 'prop-types';
import { useWindowSize } from '@react-hook/window-size';

import Modal from './components/Modal';
import toggleOverlay from './lib/toggleOverlay';
import translate from './lib/translate';

const ProfileVideo = ({ hidePrefix, videoUrl, label }) => {
	const [showModal, setShowModal] = useState(false);
	const [windowWidth, windowHeight] = useWindowSize({ leading: true });
	const ref = useOnclickOutside(() => {
		setShowModal(false);
		toggleOverlay(false);
	}, { detectIFrame: false });
	const width = windowWidth > 1000 ? 900 : windowWidth - 100;
	let height = windowWidth > 1000 ? 506 : 506 * (windowWidth / 900);
	if (height > windowHeight - 200) height = windowHeight - 200;
	let embedUrl = videoUrl;
	if (embedUrl.match(/\?/)) {
		embedUrl += '&autoplay=1&enablejsapi=1&origin=https%3A%2F%2Fminneapolisschoolfinder.org&widgetid=1';
	}	else {
		embedUrl += '?autoplay=1&enablejsapi=1&origin=https%3A%2F%2Fminneapolisschoolfinder.org&widgetid=1';
	}
	return (
		<div>
			{showModal ? (
				<Modal>
					<div className="ratings-video-modal">
						<div className="ratings-video-wrapper" ref={ref}>
							<button
								type="button"
								className="close"
								onClick={() => {
									setShowModal(false);
									toggleOverlay(false);
								}}
							>
								{translate('Close', 'Cerrar', 'Xir')}
							</button>
							<iframe
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
								allowfullscreen=""
								frameBorder="0"
								height={height}
								src={videoUrl}
								title="YouTube video player"
								width={width}
							/>
						</div>
					</div>
				</Modal>
			) : (
				<button
					type="button"
					className="modal-trigger profile-video-modal-trigger"
					onClick={() => {
						setShowModal(true);
						toggleOverlay(true);
					}}
				>
					<span>
						{translate(
							hidePrefix ? '{label}' : 'Learn more about {label}',
							hidePrefix ? '{label}' : 'Aprender más acerca de {label}',
							hidePrefix ? '{label}' : 'Wax badan ka baro {label}',
						).replace('{label}', label)}
					</span>
				</button>
			)}
		</div>
	);
};

ProfileVideo.propTypes = {
	videoUrl: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	hidePrefix: PropTypes.bool.isRequired,
};

export default ProfileVideo;
